<template>
  <v-container>
    <v-card class="table-card">
      <v-card-title>
        <v-row class="d-flex justify-space-between align-center mb-2 px-2">
          <div class="d-flex">
            <v-avatar size="100" class="elevation-6 ms-1" :color="color">
              <v-icon dark size="64">
                $clientsGroup
              </v-icon>
            </v-avatar>
            <div class="table-card-title ms-5 text-h2">
              {{ $t('clients') }}
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <v-checkbox v-model="includeSubClients" label="כולל תת לקוחות" />
            <v-text-field
              v-model="search"
              class="d-flex table-search ms-5"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            />
          </div>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="
          includeSubClients
            ? Object.values(clients)
            : Object.values(baseClients)
        "
        :search="search"
        :loading="loading"
        :class="clickable ? 'clickable' : ''"
        @click:row="handleClick"
      >
        <template v-slot:item.parent_client="{ item }">
          {{ clientsNames[item.parent_client] }}
        </template>
        <!-- <template v-slot:item.updated_at="{ item }">
          {{ item.contact_name }}
          {{ formatTime(item.updated_at) }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ formatTime(item.created_at) }}
        </template> -->
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Clients',

  components: {},

  data: () => ({
    icon: 'md-account-tie',
    color: 'blue',
    clickable: true,
    loading: false,
    search: '',
    includeSubClients: false,
    // headers: [],
  }),

  computed: {
    baseClients () {
      return this.$store.getters['clients/baseClients']
    },
    clients () {
      return this.$store.getters['clients/clients']
    },
    self () {
      return this.$store.getters['clients/self'] ?? {}
    },
    clientsNames () {
      return this.$store.getters['clients/clientsNames']
    },
    headers () {
      const headers = [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: this.$t('code'), value: 'code' },
      ]
      if (this.includeSubClients) {
        headers.push({ text: this.$t('parent_client'), value: 'parent_client' })
      }
      headers.push({
        text: this.$phraser.set('id number'),
        value: 'business_number',
      })
      headers.push({
        text: this.$phraser.set('contact name'),
        value: 'contact_name',
      })
      headers.push({
        text: this.$phraser.set('contact phone'),
        value: 'contact_phone',
      })
      return headers
    },
  },

  watch: {
    clients () {
      this.loading = false
    },
  },

  mounted () {
    moment.locale(this.$i18n.locale)
  },

  methods: {
    getClients () {
      this.$store.dispatch('clients/get')
    },
    handleClick (client) {
      if (this.clickable) {
        this.$router.push({ name: 'Client', params: { code: client.code } })
      }
    },
    formatTime (time) {
      return moment(time)._isValid ? moment(time).format('lll') : time
    },
  },
}
</script>
